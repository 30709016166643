import {
  Box,
  Button,
  Grid,
  ImageList,
  ImageListItem,
  Typography,
} from "@mui/material";

import { useValue } from "../context/ContextProvider";
import Login from "../components/user/Login";

import RoadMapLogo from "../images/theRoadMap.png";
import test from "../images/hairpin.png";
import mail from "../images/wine_profile.png";

const Landing = () => {
  const { dispatch } = useValue();

  const imagesArray = [
    /*{
            url: RoadMapLogo,
            alt: 'Road map Logo'
        },*/
    {
      url: test,
      alt: "Road map Logo",
    },
    {
      url: mail,
      alt: "Road map Logo",
    },
    /*{
            url: mail,
            alt: 'Road map Logo'
        },*/
  ];

  const text =
    "Inside, you'll find an ever growing library of cycling content. Read up on races, find your favorite climbs and cobbles, and scan through photos. Hit Explore and enjoy the ride.";

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",

        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Login />
      <Box
        sx={{
          width: "auto",
          height: "100%",
        }}
      >
        <img src={RoadMapLogo} alt="Road map Logo" width="100%" height="90%" />
      </Box>

      {/* <Typography variant="h3" >
                The Cycling Road Map
            </Typography> */}
      <Grid
        container
        sx={{
          height: "100%",
          width: "100%",
          marginTop: "20px",
          marginBottom: "50px",
        }}
      >
        <Grid
          item
          xs={6}
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
            lineHeight: 5,
          }}
        >
          <Typography variant="h3" sx={{ marginY: "20px" }}>
            The Cycling Road Map{" "}
          </Typography>
          <Typography sx={{ width: "70%", marginY: "20px" }}>{text}</Typography>
          <Button
            variant="outlined"
            size="lg"
            onClick={() => {
              dispatch({ type: "UPDATE_HOME_VIEW", payload: "Map" });
            }}
          >
            Explore
          </Button>
        </Grid>
        <Grid item xs={6} sx={{ display: "flex", marginY: "80px" }}>
          {/* todo: Images go here */}
          <ImageList sx={{ width: "80%" }} variant="" cols={2} gap={4}>
            {imagesArray.map((image, key) => (
              <ImageListItem key={key}>
                <img src={image.url} alt={image.alt} loading="lazy" />
              </ImageListItem>
            ))}
          </ImageList>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Landing;
