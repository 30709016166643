import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Paper,
} from "@mui/material";
import { AddLocationAlt, LocationOnRounded } from "@mui/icons-material";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import InfoIcon from "@mui/icons-material/Info";
import ListIcon from "@mui/icons-material/List";
import { useEffect, useRef } from "react";
import ClusterMap from "./map/ClusterMap";
import Points from "./points/Points";
import AddPoint from "./addPoint/AddPoint";
import AddTag from "./addTag/AddTag";
// import AddPolyline from './addPolyline/AddPolyline';
import AboutUs from "./aboutUs/AboutUs";
import ContactUs from "./contactUs/ContactUs";
import Protected from "./protected/Protected";
import { useValue } from "../context/ContextProvider";
import isAdmin from "../pages/dashboard/utils/isAdmin";

const BottomNav = () => {
  const {
    state: { section, currentUser },
    dispatch,
  } = useValue();
  const ref = useRef();
  useEffect(() => {
    ref.current.ownerDocument.body.scrollTop = 0;
  }, [section]);
  return (
    <Box ref={ref}>
      {isAdmin(currentUser)
        ? {
            // 0: (
            //   <Protected>
            //     <ClusterMap />
            //   </Protected>
            // ),
            0: <ClusterMap />,
            1: <Points />,
            // 2: <AddPoint />,
            // 3: <AddTag />,
            2: <AboutUs />,
          }[section]
        : {
            // 0: (
            //   <Protected>
            //     <ClusterMap />
            //   </Protected>
            // ),
            0: <ClusterMap />,
            1: <Points />,
            2: <AboutUs />,
          }[section]}
      <Paper
        elevation={3}
        sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 2 }}
      >
        {isAdmin(currentUser) ? (
          <BottomNavigation
            showLabels
            value={section}
            onChange={(e, newValue) =>
              dispatch({ type: "UPDATE_SECTION", payload: newValue })
            }
          >
            <BottomNavigationAction label="Map" icon={<LocationOnRounded />} />
            <BottomNavigationAction label="Points" icon={<ListIcon />} />

            {/* Admin only Navigations */}
            {/* <BottomNavigationAction
              label="Add Point"
              icon={<AddLocationAlt />}
            />
            <BottomNavigationAction label="Add Tag" icon={<AddLocationAlt />} /> */}
            <BottomNavigationAction label="About Us" icon={<InfoIcon />} />
          </BottomNavigation>
        ) : (
          <BottomNavigation
            showLabels
            value={section}
            onChange={(e, newValue) =>
              dispatch({ type: "UPDATE_SECTION", payload: newValue })
            }
          >
            <BottomNavigationAction label="Map" icon={<LocationOnRounded />} />
            <BottomNavigationAction label="Points" icon={<ListIcon />} />
            <BottomNavigationAction label="About Us" icon={<InfoIcon />} />
          </BottomNavigation>
        )}
      </Paper>
    </Box>
  );
};

export default BottomNav;
